





















































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import StationNavBar from '@/components/StationNavBar.vue'
import { ModNodeData, NodeInfo, NodeVariableUpdate, GlobalVariableUpdate } from '@/types/state'

import { Buffer } from 'buffer'

// import { nodesWithData } from '@/dummyNodeData'

/* helper functions */
const requiredField  = function(text?: string) { return (input: string): boolean | string => (input !== null && input !== "") || (text ?? "Verplicht") }
const onlyNumbers    = function(text?: string) { return (input: string): boolean | string => /^\d+$/.test(input) || (text ?? "Vul alleen cijfers in") }


@Component({
    components: {
        StationNavBar
    }
})
export default class ModNode extends Vue {
    /* variables */
    startDateMenu = false
    endDateMenu = false

    startDate = new Date(Date.now() - 1000*60*60*24*7*26).toISOString().substr(0, 10)   // Half a year
    endDate = new Date(Date.now()).toISOString().substr(0, 10)  // Up to now

    SNACKBAR_TIMEOUT_LENGTH = 3000
    snackbarTimeout: any = null
    snackbarText = "Waarde gewijzigd"

    headerVisible = true

    showSystemTypeForm = false
    showSystemTypeCreationForm = false
    selectedSystemType = 1

    newSystemTypeFormValid = false
    newSystemTypeId    = 0
    newSystemTypeImage = null
    newSystemTypeImageValue: any = null

    SYSTEM_TYPE_ID_RULES = [ requiredField(), onlyNumbers() ]

    updatingValue = false

    formatDate(date: string): string {
        return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
    }

    snack(text: string): void {
        this.snackbarText = text
    }


    /* getters */
    get startDateText(): string { return this.formatDate(this.startDate) }
    get endDateText(): string { return this.formatDate(this.endDate) }

    get detailNode(): NodeInfo { return this.$store.getters['modNode/NodeInfo'] }
    get nodeData(): ModNodeData { return this.$store.getters['modNode/NodeData'] }

    get isLoaded(): boolean { return this.$store.getters['modNode/IsPreloaded'] }

    get updatedValue(): boolean { return this.$store.getters['modNode/UpdatedValue'] }
    set updatedValue(newVal: boolean) { this.$store.commit("modNode/SET_UPDATED_VALUE", newVal) }

    get preloadData(): any { return this.$store.getters['addEmra/PreloadData'] }
    get systemTypeImages(): any[]  { return this.preloadData.systemTypeImages?.map((x: {systemType: number, image: string, display: any}) => ({ systemType: x.systemType, image: x.image ? Buffer.from(x.image, "base64") : null })) }
    get nodeVariables(): any[]     { return this.preloadData.nodeVariables }
    get systemTypeNodeVariableId(): number  { return this.objFromId("Systeemtype", this.nodeVariables, "name")?.id }

    get systemType(): any { return this.nodeData?.systemType }
    
    get inputAlreadyExists(): boolean {
        return this.systemTypeImages.findIndex(s => s.systemType == this.newSystemTypeId) >= 0
    }


    /* watchers */
    @Watch("systemType")
    detailNodeUpdated(): void {
        this.selectedSystemType = this.systemType || 1
    }

    @Watch("newSystemTypeImage")
    updateSystemTypeImagePreview(): void {
        if (this.newSystemTypeImage) {
            let fr = new FileReader()

            fr.onload = () => {
                this.newSystemTypeImageValue = fr.result
            }
            fr.readAsDataURL(this.newSystemTypeImage as unknown as Blob)
        } else {
            this.newSystemTypeImageValue = null
        }
    }


    /* methods */
    retrieveData(): void {
        this.$store.commit('modNode/SET_PRELOADED', false)
        this.$store.commit('modNode/SET_START_DATE', this.startDate)
        this.$store.commit('modNode/SET_END_DATE', this.endDate)

        this.$store.dispatch('modNode/updateNodeData', [this.startDate, this.endDate])
    }

    objFromId(id: string, objects: any[]|null, attr = "id"): any {
        return objects?.find(o => o[attr] == id) || ""
    }

    roundValue(val: number, decimals: number): number {
        return Number(Math.round(Number(val + 'e' + decimals)) + 'e-' + decimals);
    }

    resetSnackbarTimeout() {
        if (this.snackbarTimeout) {
            clearTimeout(this.snackbarTimeout)
            this.snackbarTimeout = null
        }
        this.snackbarTimeout = setTimeout(() => {this.$store.commit('modNode/SET_UPDATED_VALUE', false)}, this.SNACKBAR_TIMEOUT_LENGTH)
    }

    vClickOutsideIncluded(): HTMLElement[] {
        const classes = ["includeClickOutside"]
        const result: HTMLElement[] = []

        classes.forEach(name => {
            const elements = document.getElementsByClassName(name);
            (Array.from(elements) as HTMLElement[]).forEach(el => {
                result.push(el)
            })
        })

        return result
    }

    hidePopupForms(): void {
        this.showSystemTypeForm = false
        this.showSystemTypeCreationForm = false
    }

    async updateSystemType(): Promise<void> {
        try {
            this.updatingValue = true
            await this.saveUpdatedValue({ n: "Systeemtype", v: this.selectedSystemType })
            this.hidePopupForms()
            this.updatingValue = false
        } catch (_) { "" }
    }


    async saveUpdatedValue(item: {[nv: string] : any}): Promise<void> {
        const n: string = item.n
        const v: number = item.v
        if (!this.variableNames[n]) return  // Show error?
        
        const prefixAndSuffix = this.variableNames[n].split(":")
        const isGlobal = prefixAndSuffix[0] == "g"
        const dbVarName = prefixAndSuffix[1]

        if (isGlobal) {
            const globalVariableUpdate: GlobalVariableUpdate = {globalVariableName: dbVarName, newValue: v}
            await this.$store.dispatch('modNode/updateGlobalVariable', globalVariableUpdate)
                .then(() => { this.snackbarText = "Waarde gewijzigd"; this.resetSnackbarTimeout() })
        } else {
            const nodeVariableUpdate: NodeVariableUpdate = {nodeId: "", nodeVariableName: dbVarName, newValue: v}
            await this.$store.dispatch('modNode/updateNodeVariable', nodeVariableUpdate)
                .then(() => { this.snackbarText = "Waarde gewijzigd"; this.resetSnackbarTimeout() })
        }
    }

    createNewSystemType(): void {
        let modelName = "SystemTypeImage"; let stateName = "systemTypeImages"
        let data = {
            SystemType: this.newSystemTypeId,
            Image: this.newSystemTypeImageValue ? Buffer.from(this.newSystemTypeImageValue, "ascii").toString("base64") : null
        }
        try {
            this.$store.dispatch("adminCrud/Create", { Model: modelName, Entity: data })
                .then((result: any) => {
                    this.$store.commit("addEmra/ADD_DATA", { Model: stateName, Entity: result.entity })
                    if (!result.err) {
                        this.snackbarText = "Data succesvol opgeslagen"
                        this.resetSnackbarTimeout()
                        this.showSystemTypeCreationForm = false
                    }
                })
        } catch (_) { "" }
    }

    headerVisibilityChanged(isVisible: boolean) {
        this.headerVisible = isVisible
    }


    created(): void {
        this.$store.dispatch('modNode/init')
    }





    headerTextWidth = "50%"
    variableNames: {[name: string] : string} = {
        // global
        "CO₂ kg/kWh": "g:CO2 kg/kWh",
        "CO₂ kg/m³ gas": "g:CO2 kg/m^3",
        // node-specific
        "Koudwatertemperatuur [°C]": "n:Koudwatertemperatuur",
        "Warmwatertemperatuur [°C]": "n:Warmwatertemperatuur",
        "Rendement warmwateropwekker [%]": "n:Rendement warmwateropwekker",
        "Stilstandsverlies [%]": "n:Stilstandsverlies",
        "Kookgas per jaar [m³]": "n:Kookgas per jaar",
        "CV-ketel pompverlies [%]": "n:CV-ketel pompverlies",
        "Warmtepomp pompverlies [%]": "n:Warmtepomp pompverlies",
        "Energiekosten gas [€]": "n:Gasprijs",
        "Energiekosten kWh [€]": "n:Elektriciteitsprijs",
        "Systeemtype": "n:Systeemtype"
    }


    // Table data
    globalHeaders = [
        {text: "Gegeven", value: "n", width: this.headerTextWidth},
        {text: "Waarde", value: "v"}
    ]
    get globalItems(): any {
        const gd = this.nodeData?.globalData
        if (!gd) return []
        return [
            {n: "CO₂ kg/kWh", v: gd.cO2_kg_kWh},
            {n: "CO₂ kg/m³ gas", v: gd.cO2_kg_m3_gas}
        ].map(x => {
            return {n: x.n, v: this.roundValue(x.v, 3)}
        })
    }

    warmWaterHeaders = [
        {text: "Gegeven", value: "n", width: this.headerTextWidth},
        {text: "Waarde", value: "v"}
    ]
    get warmWaterItems(): any {
        const wwd = this.nodeData?.warmWaterData
        const wwr = this.nodeData?.warmWaterResult
        if (!wwd || !wwr) return []
        return [
            {n: "Mx", v: wwd.mx},
            {n: "Cx", v: wwd.cx},
            {n: "Verbruik warmwater [m³]", v: wwd.warmWaterUsage_m3},
            {n: "Koudwatertemperatuur [°C]", v: wwd.coldWaterTemperature_C},
            {n: "Warmwatertemperatuur [°C]", v: wwd.warmWaterTemperature_C},
            {n: "Rendement warmwateropwekker [%]", v: wwd.warmWaterGeneratorGain_percent},
            {n: "Stilstandsverlies [%]", v: wwd.idleLoss_percent},
            {n: "Natuurkundig [MJ]", v: wwr.physical_MJ},
            {n: "Met rendementsverrekening CV-ketel [MJ]", v: wwr.withGainAdjustmentCVKetel_MJ},
            {n: "Met rendementsverrekening CV-ketel en stilstandsverlies [MJ]", v: wwr.withGainAdjustmentCVKetelAndIdleLoss_MJ},
            {n: "Natuurkundig [kWh]", v: wwr.physical_kWh},
            {n: "Met rendementsverrekening CV-ketel [kWh]", v: wwr.withGainAdjustmentCVKetel_kWh},
            {n: "Met rendementsverrekening CV-ketel en stilstandsverlies [kWh]", v: wwr.withGainAdjustmentCVKetelAndIdleLoss_kWh},
            {n: "Gas natuurkundig vanuit MJ [m³]", v: wwr.gasPhysicalFromMJ_m3},
            {n: "Gas natuurkundig vanuit kWh [m³]", v: wwr.gasPhysicalFromkWh_m3},
            {n: "Gas berekend vanuit MJ met CV-rendement [m³]", v: wwr.gasFromMJWithCVGain_m3},
            {n: "Gas berekend vanuit kWh met CV-rendement [m³]", v: wwr.gasFromkWhWithCVGain_m3},
            {n: "Gas berekend vanuit MJ met alle verliezen [m³]", v: wwr.gasFromMJWithAllLosses_m3},
            {n: "Gas berekend vanuit kWh met alle verliezen [m³]", v: wwr.gasFromkWhWithAllLosses_m3}
        ].map(x => {
            return {n: x.n, v: this.roundValue(x.v, 3)}
        })
    }

    cookingHeaders = [
        {text: "Gegeven", value: "n", width: this.headerTextWidth},
        {text: "Waarde", value: "v"}
    ]
    get cookingItems(): any {
        const cd = this.nodeData?.cookingData
        const cr = this.nodeData?.cookingResult
        if (!cd || !cr) return []
        return [
            {n: "Kookgas per jaar [m³]", v: cd.cookingGasPerYear_m3},
            {n: "Kookgas per dag [m³]", v: cr.cookingGasPerDay_m3},
            {n: "Kookgas over periode [m³]", v: cr.cookingGasThroughPeriod_m3}
        ].map(x => {
            return {n: x.n, v: this.roundValue(x.v, 3)}
        })
    }

    heatingHeaders = [
        {text: "Gegeven", value: "n", width: this.headerTextWidth},
        {text: "Waarde", value: "v"}
    ]
    get heatingItems(): any {
        const hd = this.nodeData?.heatingData
        const hr = this.nodeData?.heatingResult
        if (!hd || !hr) return []
        return [
            {n: "Gasmeter [m³]", v: hd.gasUsage_m3},
            {n: "kWh-meter warmtepomp [kWh]", v: hd.electricityUsageHeatPump_kWh},
            {n: "GJ-meter 1 CV-ketel [GJ]", v: hd.energyCVKetel_GJ},
            {n: "GJ-meter 2 warmtepomp [GJ]", v: hd.energyHeatPump_GJ},
            {n: "CV-ketel pompverlies [%]", v: hd.cvKetelPumpLoss_percent},
            {n: "Warmtepomp pompverlies [%]", v: hd.heatPumpPumpLoss_percent},
            {n: "Gasverbruik verwarming [m³]", v: hr.gasUsageHeating_m3},
            {n: "Natuurkundig vanuit gas installatie [GJ]", v: hr.physicalFromGasInstallation_GJ},
            {n: "GJ-meter 1 CV-ketel gemeten energie verrekend met pompverlies [GJ]", v: hr.measuredEnergyCVKetelAdjustedWithPumpLoss_GJ},
            {n: "Rendement berekend GJ-meter 1 CV-ketel [%]", v: hr.calculatedGainEnergyCVKetel_percent},
            {n: "Natuurkundig vanuit kWh warmtepompinstallatie [GJ]", v: hr.physicalFromkWhHeatPumpInstallation_GJ},
            {n: "GJ-meter 2 warmtepomp gemeten energie [GJ]", v: hr.measuredEnergyHeatPump_GJ},
            {n: "Warmtepomp gemeten energie verrekend met pompverlies [GJ]", v: hr.measuredEnergyHeatPumpAdjustedWithPumpLoss_GJ},
            {n: "Rendement berekend warmtepomp [%]", v: hr.calculatedGainHeatPump_percent},
            {n: "GJ energie installatie [GJ]", v: hr.energyInstallation_GJ},
            {n: "Totaal gemeten energie verrekend met pompverlies [GJ]", v: hr.totalMeasuredEnergyAdjustedWithPumpLoss_GJ},
            {n: "Rendement berekend installatie [%]", v: hr.calculatedGainInstallation_percent}
        ].map(x => {
            return {n: x.n, v: this.roundValue(x.v, 3)}
        })
    }

    financialAndEnvironmentalHeaders = [
        {text: "Gegeven", value: "n", width: this.headerTextWidth},
        {text: "Waarde", value: "v"}
    ]
    get financialAndEnvironmentalItems(): any {
        const fed = this.nodeData?.financialAndEnvironmentalData
        const fer = this.nodeData?.financialAndEnvironmentalResult
        if (!fed || !fer) return []
        return [
            {n: "Energiekosten gas [€]", v: fed.energyCostsGas_euros},
            {n: "Energiekosten kWh [€]", v: fed.energyCostsElectricity_euros},
            {n: "Energiekosten [€]", v: fer.energyCostsTotal_euros},
            {n: "Fictief gasverbruik verwarming [m³]", v: fer.fictionalGasUsageHeating_m3},
            {n: "Fictief alleen gas [€]", v: fer.fictionalGasOnly_euros},
            {n: "Besparing [€]", v: fer.saving_euros},
            {n: "CO₂ [kg]", v: fer.cO2_kg},
            {n: "CO₂ fictief [kg]", v: fer.cO2Fictional_kg},
            {n: "CO₂-reductie [kg]", v: fer.cO2Reduction_kg}
        ].map(x => {
            return {n: x.n, v: this.roundValue(x.v, 3)}
        })
    }

    // {n: "", v: },
}

