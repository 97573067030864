




















































import { Component, Prop, Vue } from 'vue-property-decorator'

import { NodeInfo, NodeInfoFunctions } from '@/types/state'


@Component({
    components: {
    }
})
export default class StationNavBar extends Vue {
    @Prop()
    setNodeInfoStoreName!: string  // e.g. 'modNode'

    searchValue = ''
    selectedNode = 0

    setDetailNode(node: NodeInfo): void {
        this.$store.commit('detailNode/SET_NODE_INFO', { ...node })
        this.$store.commit('meters/SET_NODE_INFO', { ...node })
        this.$store.commit('modNode/SET_NODE_INFO', { ...node })
        this.$store.commit('emraPageInfo/SET_NODE_INFO', { ...node } )

        this.$store.dispatch(`${this.setNodeInfoStoreName}/init`)
    }

    containsTerm(target: string, term: string): boolean {
        return target.toLowerCase().includes(term.toLowerCase())
    }

    filterNodesByAlias(nodes: Array<NodeInfo>): Array<NodeInfo> {
        return NodeInfoFunctions.sortDesc(nodes)
    }

    get nodes(): Array<NodeInfo> {
        return this.$store.getters['default/NodeInfos']
    }

    get updatedMeters(): Array<any> {
        return this.$store.getters["default/UpdatedMeters"]
    }

    get filteredNodes(): Array<NodeInfo> {
        return this.filterNodesByAlias(this.nodes.filter(n => this.containsTerm(n.alias, this.searchValue)))
    }

}
